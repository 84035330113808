<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn icon :disabled="loading" @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                    class="mt-5"
                    :label="$store.getters.translate('scheme')"
                    v-model="parent.name"
                    disabled/>
                <v-text-field
                    :label="$store.getters.translate('name')"
                    v-model="record.name"/>
                <v-text-field
                    :label="$store.getters.translate('duration')"
                    v-model="record.duration"
                    v-mask="'##:##'"
                    placeholder="04:00"/>
                <v-text-field
                    v-mask="'####'"
                    :label="$store.getters.translate('max_candidates')"
                    v-model="record.max_candidates"/>
                <v-slider
                    class="mt-8"
                    :label="$store.getters.translate('cesuur')"
                    v-model="record.cesuur"
                    thumb-label="always"/>
                <v-switch
                    :label="$store.getters.translate('re_certification')"
                    v-model="record.re_certification"/>
                <v-text-field
                    prepend-inner-icon="mdi-currency-eur"
                    :label="$store.getters.translate('price')"
                    v-model="record.price"
                    type="number"/>
                <v-text-field
                    :label="$store.getters.translate('validity_duration')"
                    v-model="record.validity_duration"
                    type="number"
                    v-mask="'##'"/>
                <v-textarea
                    rows="4"
                    :label="$store.getters.translate('description')"
                    v-model="record.description"/>
                <v-select
                    v-if="parent.scheme_owner && parent.scheme_owner.toLowerCase() === 'stipel'"
                    :class="'ma-0 pt-'+$store.getters.lineheight"
                    :label="$store.getters.translate('stipel_name')"
                    v-model="record.stipel_name"
                    validate-on-blur
                    :items="stipel_names"/>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
        <v-footer-panel ref="footer_panel" :model="page.name"/>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "../../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../../plugins/helpFunctions";

export default {
  props: ["parent", "modal_name"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("edit_schemesession"),
        name: "schemesessions",
        model: "schemesession",
      },
      record: {
        scheme_id: this.parent.id,
        name: null,
        description: null,
        type: null,
        cesuur: 0,
        re_certification: false,
        collection_id: null,
        warrant_id: null,
        assessment_protocol_id: null,
        price: 0,
        validity_duration: 12,
        max_candidates: 10,
        start_times: ["","","","","","","","","","","",""]
      },
      stipel_names: [
        {
          value: "theory",
          text: this.$store.getters.translate("theory"),
        },
        {
          value: "practical_a",
          text: this.$store.getters.translate("practical_a"),
        },
        {
          value: "practical_b",
          text: this.$store.getters.translate("practical_b"),
        },
        {
          value: "primary_education",
          text: this.$store.getters.translate("primary_education"),
        },
        {
          value: "theory_open",
          text: this.$store.getters.translate("theory_open"),
        },
      ],
      loading: false,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(id) {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + id)
        .then((response) => {
          this.record = response.data;
          this.$refs.footer_panel.record = this.record;
          this.$refs.footer_panel.load();
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    save() {
      if(!this.loading) {
        this.loading = true;
        this.record.scheme_id = this.parent.id;
        this.$http
            .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
            .then(() => {
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("successfully_saved"));
              this.$emit("refresh");
              this.reset();
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
      }
    },
    reset() {
      this.record = {
        scheme_id: this.parent.id,
        name: null,
        description: null,
        type: null,
        cesuur: 0,
        re_certification: false,
        collection_id: null,
        warrant_id: null,
        assessment_protocol_id: null,
        price: 0,
        validity_duration: 12,
        max_candidates: 10,
        start_times: ["","","","","","","","","","","",""]
      };
      this.closeModal(this.modal_name);
    },
  },
};
</script>
